import { default as indexfFeK6VNF6SMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/index.vue?macro=true";
import { default as _91id_93qzvOhPj95cMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/bundle-choices/[id].vue?macro=true";
import { default as indexQYosSn3EV5Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/bundle-choices/index.vue?macro=true";
import { default as _91id_93RNaDxAJetOMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/bundles/[id].vue?macro=true";
import { default as indexjHWkNXyiU4Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/bundles/index.vue?macro=true";
import { default as _91menuCategoryId_93LpxFIKyPHeMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/menus/[id]/categories/[menuCategoryId].vue?macro=true";
import { default as index54X5Nk9Z20Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/menus/[id]/index.vue?macro=true";
import { default as indexUW9D8cRiP7Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/menus/index.vue?macro=true";
import { default as indexgbuxjbQHjBMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/modifiers/index.vue?macro=true";
import { default as indexKhV9frhqqXMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/modifiers/modifier-groups/[id]/index.vue?macro=true";
import { default as _91modifierId_938Ihl1uDwAlMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue?macro=true";
import { default as _91id_93rH2iA32ZZ6Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/modifiers/modifier-groups/[id].vue?macro=true";
import { default as indexsfEzdTtpkPMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/modifiers/modifier-groups/index.vue?macro=true";
import { default as indexKH4PmoHJDsMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/products/index.vue?macro=true";
import { default as _91id_93TckzdvBtVaMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/products/product-categories/[id].vue?macro=true";
import { default as indexSrixkVbvWjMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/products/product-categories/index.vue?macro=true";
import { default as _91id_93nh67eyBuHiMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/products/products/[id].vue?macro=true";
import { default as indexh986fkuAIpMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/products/products/index.vue?macro=true";
import { default as merchantsyYKQIofbtPMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/merchants.vue?macro=true";
import { default as _91accountId_93MuhlLIIAyeMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/account-configurations/[id]/accounts/[accountId].vue?macro=true";
import { default as indexRgPV3dEGZyMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/account-configurations/[id]/index.vue?macro=true";
import { default as _91id_93yToRgdWwV7Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/account-configurations/[id].vue?macro=true";
import { default as indexK21Qivb0k1Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/account-configurations/index.vue?macro=true";
import { default as credentialsKYhq4eAbdGMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/connections/[id]/credentials.vue?macro=true";
import { default as index4kC4MuWJVyMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/connections/[id]/index.vue?macro=true";
import { default as _91id_93mtB3WBHRujMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/connections/[id].vue?macro=true";
import { default as _91driver_93Weip3g0NbpMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/connections/create/[driver].vue?macro=true";
import { default as indexPuqidinsImMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/connections/create/index.vue?macro=true";
import { default as indexmVkNH9dbqjMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/connections/index.vue?macro=true";
import { default as _91id_935S1V2x91cEMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/discounts/discounts/[id].vue?macro=true";
import { default as indexnYw0ROj1xvMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/discounts/discounts/index.vue?macro=true";
import { default as indexYNqdTFWU6LMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/discounts/index.vue?macro=true";
import { default as indexy8QoW5SYYAMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/employees/[id]/index.vue?macro=true";
import { default as logLECHfnEMg9Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/employees/[id]/log.vue?macro=true";
import { default as _91id_93wY02ZrNG35Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/employees/[id].vue?macro=true";
import { default as indexnlwwUGbweuMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/employees/index.vue?macro=true";
import { default as index08a8wiyCGIMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/images/index.vue?macro=true";
import { default as kitchensq8YtdrmFyeMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/kitchens.vue?macro=true";
import { default as index9r6AESdOdXMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/payments/index.vue?macro=true";
import { default as payment_45methods1jExQrs9srMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/payments/payment-methods.vue?macro=true";
import { default as indexqRfKchScdFMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/reservations/index.vue?macro=true";
import { default as indexKxHPjxmHg9Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/reviews/index.vue?macro=true";
import { default as categoriesOBac1DPtgbMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stock/categories.vue?macro=true";
import { default as indexemCaNXDdVBMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stock/index.vue?macro=true";
import { default as itemsAVno4FixDIMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stock/items.vue?macro=true";
import { default as modifier_45recipesih7SbhXS7PMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stock/modifier-recipes.vue?macro=true";
import { default as _91storeId_93hjpDtv2wfPMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stock/stores/[storeId].vue?macro=true";
import { default as variant_45recipesGXRHI0Go1NMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stock/variant-recipes.vue?macro=true";
import { default as stockrLVQc6vBHkMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stock.vue?macro=true";
import { default as bookkeepingSsQ8FrqalCMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stores/[id]/bookkeeping.vue?macro=true";
import { default as _91floorId_93TtAPNUGRe6Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stores/[id]/floors/[floorId].vue?macro=true";
import { default as indexth9GQHXpDDMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stores/[id]/floors/index.vue?macro=true";
import { default as hardwareUktHvADAwQMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stores/[id]/hardware.vue?macro=true";
import { default as indextTlj9zu2kSMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stores/[id]/index.vue?macro=true";
import { default as kitchenfOqgndO0HrMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stores/[id]/kitchen.vue?macro=true";
import { default as stock8whTYUnEVYMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stores/[id]/stock.vue?macro=true";
import { default as _91id_931pEOIc0rKCMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stores/[id].vue?macro=true";
import { default as indexpxlSQinXwCMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stores/index.vue?macro=true";
import { default as indexRBFX8xDXiPMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/terminals/colors/index.vue?macro=true";
import { default as _91id_93almpqPgEsAMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/terminals/folders/[id].vue?macro=true";
import { default as index5MJRMzwqqFMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/terminals/folders/index.vue?macro=true";
import { default as indexVj5eOI3n2IMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/terminals/terminals/[id]/index.vue?macro=true";
import { default as logGI8eMXieMwMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/terminals/terminals/[id]/log.vue?macro=true";
import { default as navigationalLSIwwuoYMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/terminals/terminals/[id]/navigation.vue?macro=true";
import { default as _91id_93Sc2iNTZWjsMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/terminals/terminals/[id].vue?macro=true";
import { default as indexPIGsSFy72QMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/terminals/terminals/index.vue?macro=true";
import { default as index4FcEI64A8UMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/profile/index.vue?macro=true";
import { default as downloadg7b4iUPjQFMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/reports/download.vue?macro=true";
import { default as efficiency3PEBOIgQdJMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/reports/efficiency.vue?macro=true";
import { default as index7UF50phR0iMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/reports/index.vue?macro=true";
import { default as productionWZ4nPqz3u7Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/reports/production.vue?macro=true";
import { default as tips7CBYxEk1kCMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/reports/tips.vue?macro=true";
import { default as indexT0XbkCp5FwMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settings/index.vue?macro=true";
import { default as index0viR6X6TjaMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/cancellations/index.vue?macro=true";
import { default as reasons9MNyXX7jl6Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/cancellations/reasons.vue?macro=true";
import { default as cash_45transfersYgW3lp536XMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/cash-transfers/cash-transfers.vue?macro=true";
import { default as _91id_93lvE6Sj25zQMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/cash-transfers/categories/[id].vue?macro=true";
import { default as indexC1gLYFo7akMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/cash-transfers/categories/index.vue?macro=true";
import { default as indexE8Xm1MLMR8Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/cash-transfers/index.vue?macro=true";
import { default as indexFc0RjbaJ8RMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/channel-settlements/[id]/index.vue?macro=true";
import { default as logYjQMdwoll4Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/channel-settlements/[id]/log.vue?macro=true";
import { default as ordersh2r6ETXgk5Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/channel-settlements/[id]/orders.vue?macro=true";
import { default as _91channelSettlementSnapshotId_93p0bpQlNJhoMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue?macro=true";
import { default as indexLELkEkfRuGMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/channel-settlements/[id]/snapshots/index.vue?macro=true";
import { default as _91id_93a5X0BYVlSbMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/channel-settlements/[id].vue?macro=true";
import { default as _91id_93s3W7MKgMG8Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/delivery-providers/[id].vue?macro=true";
import { default as indexh21BjRhNpHMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/delivery-providers/index.vue?macro=true";
import { default as _91id_93TFvL52i3T6Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/order-providers/[id].vue?macro=true";
import { default as indexdBmFizFvdXMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/order-providers/index.vue?macro=true";
import { default as _91id_930g9iOBuMs1Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/orders/[id].vue?macro=true";
import { default as indexdghRQIGh1yMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/orders/index.vue?macro=true";
import { default as bookkeepingIn319tJThEMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/store-settlements/[id]/bookkeeping.vue?macro=true";
import { default as channel_45settlementsQ6UhNsIOzdMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/store-settlements/[id]/channel-settlements.vue?macro=true";
import { default as index7HXgz3sEqVMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/store-settlements/[id]/index.vue?macro=true";
import { default as logbyWtHlhMBAMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/store-settlements/[id]/log.vue?macro=true";
import { default as ordersjiAJVS2fvuMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/store-settlements/[id]/orders.vue?macro=true";
import { default as _91reconciliationId_93klHYF3cyXgMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/store-settlements/[id]/reconciliations/[reconciliationId].vue?macro=true";
import { default as indexbaqVuOEsN1Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/store-settlements/[id]/reconciliations/index.vue?macro=true";
import { default as _91id_93oXlR4lq0FoMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/store-settlements/[id].vue?macro=true";
import { default as closing_45errors7Oa9xDy4hbMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/store-settlements/closing-errors.vue?macro=true";
import { default as indexuYVlBVL7tcMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/store-settlements/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/index.vue")
  },
  {
    name: "inventory-bundle-choices-id",
    path: "/inventory/bundle-choices/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/bundle-choices/[id].vue")
  },
  {
    name: "inventory-bundle-choices",
    path: "/inventory/bundle-choices",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/bundle-choices/index.vue")
  },
  {
    name: "inventory-bundles-id",
    path: "/inventory/bundles/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/bundles/[id].vue")
  },
  {
    name: "inventory-bundles",
    path: "/inventory/bundles",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/bundles/index.vue")
  },
  {
    name: "inventory-menus-id-categories-menuCategoryId",
    path: "/inventory/menus/:id()/categories/:menuCategoryId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/menus/[id]/categories/[menuCategoryId].vue")
  },
  {
    name: "inventory-menus-id",
    path: "/inventory/menus/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/menus/[id]/index.vue")
  },
  {
    name: "inventory-menus",
    path: "/inventory/menus",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/menus/index.vue")
  },
  {
    name: "inventory-modifiers",
    path: "/inventory/modifiers",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/modifiers/index.vue")
  },
  {
    name: _91id_93rH2iA32ZZ6Meta?.name,
    path: "/inventory/modifiers/modifier-groups/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/modifiers/modifier-groups/[id].vue"),
    children: [
  {
    name: "inventory-modifiers-modifier-groups-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/modifiers/modifier-groups/[id]/index.vue")
  },
  {
    name: "inventory-modifiers-modifier-groups-id-modifiers-modifierId",
    path: "modifiers/:modifierId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue")
  }
]
  },
  {
    name: "inventory-modifiers-modifier-groups",
    path: "/inventory/modifiers/modifier-groups",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/modifiers/modifier-groups/index.vue")
  },
  {
    name: "inventory-products",
    path: "/inventory/products",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/products/index.vue")
  },
  {
    name: "inventory-products-product-categories-id",
    path: "/inventory/products/product-categories/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/products/product-categories/[id].vue")
  },
  {
    name: "inventory-products-product-categories",
    path: "/inventory/products/product-categories",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/products/product-categories/index.vue")
  },
  {
    name: "inventory-products-products-id",
    path: "/inventory/products/products/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/products/products/[id].vue")
  },
  {
    name: "inventory-products-products",
    path: "/inventory/products/products",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/inventory/products/products/index.vue")
  },
  {
    name: "merchants",
    path: "/merchants",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/merchants.vue")
  },
  {
    name: _91id_93yToRgdWwV7Meta?.name,
    path: "/point-of-sale/account-configurations/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/account-configurations/[id].vue"),
    children: [
  {
    name: "point-of-sale-account-configurations-id-accounts-accountId",
    path: "accounts/:accountId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/account-configurations/[id]/accounts/[accountId].vue")
  },
  {
    name: "point-of-sale-account-configurations-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/account-configurations/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-account-configurations",
    path: "/point-of-sale/account-configurations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/account-configurations/index.vue")
  },
  {
    name: _91id_93mtB3WBHRujMeta?.name,
    path: "/point-of-sale/connections/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/connections/[id].vue"),
    children: [
  {
    name: "point-of-sale-connections-id-credentials",
    path: "credentials",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/connections/[id]/credentials.vue")
  },
  {
    name: "point-of-sale-connections-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/connections/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-connections-create-driver",
    path: "/point-of-sale/connections/create/:driver()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/connections/create/[driver].vue")
  },
  {
    name: "point-of-sale-connections-create",
    path: "/point-of-sale/connections/create",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/connections/create/index.vue")
  },
  {
    name: "point-of-sale-connections",
    path: "/point-of-sale/connections",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/connections/index.vue")
  },
  {
    name: "point-of-sale-discounts-discounts-id",
    path: "/point-of-sale/discounts/discounts/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/discounts/discounts/[id].vue")
  },
  {
    name: "point-of-sale-discounts-discounts",
    path: "/point-of-sale/discounts/discounts",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/discounts/discounts/index.vue")
  },
  {
    name: "point-of-sale-discounts",
    path: "/point-of-sale/discounts",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/discounts/index.vue")
  },
  {
    name: _91id_93wY02ZrNG35Meta?.name,
    path: "/point-of-sale/employees/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/employees/[id].vue"),
    children: [
  {
    name: "point-of-sale-employees-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/employees/[id]/index.vue")
  },
  {
    name: "point-of-sale-employees-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/employees/[id]/log.vue")
  }
]
  },
  {
    name: "point-of-sale-employees",
    path: "/point-of-sale/employees",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/employees/index.vue")
  },
  {
    name: "point-of-sale-images",
    path: "/point-of-sale/images",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/images/index.vue")
  },
  {
    name: "point-of-sale-kitchens",
    path: "/point-of-sale/kitchens",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/kitchens.vue")
  },
  {
    name: "point-of-sale-payments",
    path: "/point-of-sale/payments",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/payments/index.vue")
  },
  {
    name: "point-of-sale-payments-payment-methods",
    path: "/point-of-sale/payments/payment-methods",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/payments/payment-methods.vue")
  },
  {
    name: "point-of-sale-reservations",
    path: "/point-of-sale/reservations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/reservations/index.vue")
  },
  {
    name: "point-of-sale-reviews",
    path: "/point-of-sale/reviews",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/reviews/index.vue")
  },
  {
    name: stockrLVQc6vBHkMeta?.name,
    path: "/point-of-sale/stock",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stock.vue"),
    children: [
  {
    name: "point-of-sale-stock-categories",
    path: "categories",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stock/categories.vue")
  },
  {
    name: "point-of-sale-stock",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stock/index.vue")
  },
  {
    name: "point-of-sale-stock-items",
    path: "items",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stock/items.vue")
  },
  {
    name: "point-of-sale-stock-modifier-recipes",
    path: "modifier-recipes",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stock/modifier-recipes.vue")
  },
  {
    name: "point-of-sale-stock-stores-storeId",
    path: "stores/:storeId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stock/stores/[storeId].vue")
  },
  {
    name: "point-of-sale-stock-variant-recipes",
    path: "variant-recipes",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stock/variant-recipes.vue")
  }
]
  },
  {
    name: _91id_931pEOIc0rKCMeta?.name,
    path: "/point-of-sale/stores/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stores/[id].vue"),
    children: [
  {
    name: "point-of-sale-stores-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stores/[id]/bookkeeping.vue")
  },
  {
    name: "point-of-sale-stores-id-floors-floorId",
    path: "floors/:floorId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stores/[id]/floors/[floorId].vue")
  },
  {
    name: "point-of-sale-stores-id-floors",
    path: "floors",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stores/[id]/floors/index.vue")
  },
  {
    name: "point-of-sale-stores-id-hardware",
    path: "hardware",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stores/[id]/hardware.vue")
  },
  {
    name: "point-of-sale-stores-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stores/[id]/index.vue")
  },
  {
    name: "point-of-sale-stores-id-kitchen",
    path: "kitchen",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stores/[id]/kitchen.vue")
  },
  {
    name: "point-of-sale-stores-id-stock",
    path: "stock",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stores/[id]/stock.vue")
  }
]
  },
  {
    name: "point-of-sale-stores",
    path: "/point-of-sale/stores",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/stores/index.vue")
  },
  {
    name: "point-of-sale-terminals-colors",
    path: "/point-of-sale/terminals/colors",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/terminals/colors/index.vue")
  },
  {
    name: "point-of-sale-terminals-folders-id",
    path: "/point-of-sale/terminals/folders/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/terminals/folders/[id].vue")
  },
  {
    name: "point-of-sale-terminals-folders",
    path: "/point-of-sale/terminals/folders",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/terminals/folders/index.vue")
  },
  {
    name: _91id_93Sc2iNTZWjsMeta?.name,
    path: "/point-of-sale/terminals/terminals/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/terminals/terminals/[id].vue"),
    children: [
  {
    name: "point-of-sale-terminals-terminals-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/terminals/terminals/[id]/index.vue")
  },
  {
    name: "point-of-sale-terminals-terminals-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/terminals/terminals/[id]/log.vue")
  },
  {
    name: "point-of-sale-terminals-terminals-id-navigation",
    path: "navigation",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/terminals/terminals/[id]/navigation.vue")
  }
]
  },
  {
    name: "point-of-sale-terminals-terminals",
    path: "/point-of-sale/terminals/terminals",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/point-of-sale/terminals/terminals/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/profile/index.vue")
  },
  {
    name: "reports-download",
    path: "/reports/download",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/reports/download.vue")
  },
  {
    name: "reports-efficiency",
    path: "/reports/efficiency",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/reports/efficiency.vue")
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/reports/index.vue")
  },
  {
    name: "reports-production",
    path: "/reports/production",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/reports/production.vue")
  },
  {
    name: "reports-tips",
    path: "/reports/tips",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/reports/tips.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settings/index.vue")
  },
  {
    name: "settlement-cancellations",
    path: "/settlement/cancellations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/cancellations/index.vue")
  },
  {
    name: "settlement-cancellations-reasons",
    path: "/settlement/cancellations/reasons",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/cancellations/reasons.vue")
  },
  {
    name: "settlement-cash-transfers-cash-transfers",
    path: "/settlement/cash-transfers/cash-transfers",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/cash-transfers/cash-transfers.vue")
  },
  {
    name: "settlement-cash-transfers-categories-id",
    path: "/settlement/cash-transfers/categories/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/cash-transfers/categories/[id].vue")
  },
  {
    name: "settlement-cash-transfers-categories",
    path: "/settlement/cash-transfers/categories",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/cash-transfers/categories/index.vue")
  },
  {
    name: "settlement-cash-transfers",
    path: "/settlement/cash-transfers",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/cash-transfers/index.vue")
  },
  {
    name: _91id_93a5X0BYVlSbMeta?.name,
    path: "/settlement/channel-settlements/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/channel-settlements/[id].vue"),
    children: [
  {
    name: "settlement-channel-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/channel-settlements/[id]/index.vue")
  },
  {
    name: "settlement-channel-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/channel-settlements/[id]/log.vue")
  },
  {
    name: "settlement-channel-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/channel-settlements/[id]/orders.vue")
  },
  {
    name: "settlement-channel-settlements-id-snapshots-channelSettlementSnapshotId",
    path: "snapshots/:channelSettlementSnapshotId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue")
  },
  {
    name: "settlement-channel-settlements-id-snapshots",
    path: "snapshots",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/channel-settlements/[id]/snapshots/index.vue")
  }
]
  },
  {
    name: "settlement-delivery-providers-id",
    path: "/settlement/delivery-providers/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/delivery-providers/[id].vue")
  },
  {
    name: "settlement-delivery-providers",
    path: "/settlement/delivery-providers",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/delivery-providers/index.vue")
  },
  {
    name: "settlement-order-providers-id",
    path: "/settlement/order-providers/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/order-providers/[id].vue")
  },
  {
    name: "settlement-order-providers",
    path: "/settlement/order-providers",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/order-providers/index.vue")
  },
  {
    name: "settlement-orders-id",
    path: "/settlement/orders/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/orders/[id].vue")
  },
  {
    name: "settlement-orders",
    path: "/settlement/orders",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/orders/index.vue")
  },
  {
    name: _91id_93oXlR4lq0FoMeta?.name,
    path: "/settlement/store-settlements/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/store-settlements/[id].vue"),
    children: [
  {
    name: "settlement-store-settlements-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/store-settlements/[id]/bookkeeping.vue")
  },
  {
    name: "settlement-store-settlements-id-channel-settlements",
    path: "channel-settlements",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/store-settlements/[id]/channel-settlements.vue")
  },
  {
    name: "settlement-store-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/store-settlements/[id]/index.vue")
  },
  {
    name: "settlement-store-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/store-settlements/[id]/log.vue")
  },
  {
    name: "settlement-store-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/store-settlements/[id]/orders.vue")
  },
  {
    name: "settlement-store-settlements-id-reconciliations-reconciliationId",
    path: "reconciliations/:reconciliationId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/store-settlements/[id]/reconciliations/[reconciliationId].vue")
  },
  {
    name: "settlement-store-settlements-id-reconciliations",
    path: "reconciliations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/store-settlements/[id]/reconciliations/index.vue")
  }
]
  },
  {
    name: "settlement-store-settlements-closing-errors",
    path: "/settlement/store-settlements/closing-errors",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/store-settlements/closing-errors.vue")
  },
  {
    name: "settlement-store-settlements",
    path: "/settlement/store-settlements",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20241213122306/pages/settlement/store-settlements/index.vue")
  }
]